import { ResolvableModelClass }    from '@mathquis/modelx-resolvables/lib/types/AbstractResolvableModel';
import { AbstractResolvableModel } from '@mathquis/modelx-resolvables';
import ApiCollection               from '@widesk-core/models/ApiCollection';
import ModelDictionary             from '@widesk-core/models/ModelDictionary';
import { flattenObject }           from '@widesk-core/tools/flattenObject';
import { getIdFromUrn }            from '@widesk-core/tools/identifier';
import _get                        from 'lodash/get';
import _uniqueId                   from 'lodash/uniqueId';
import { action }                  from 'mobx';
import { override }                from 'mobx';
import { ApiModelWhenIsLoaded }    from '@widesk-core/mixins/ApiModelWhenIsLoaded';

// AbstractResolvableModel.getDefaults().log = true;

type UrnData = { partition: string; resource: string; service: string; };

export default class ApiModel extends ApiModelWhenIsLoaded(AbstractResolvableModel) {
	public uniqueId = _uniqueId();

	public static urnData: UrnData;

	// noinspection JSUnusedGlobalSymbols
	static getResolvableCollection() {
		return ApiCollection;
	}

	public get urnData(): UrnData {
		return (this.constructor as never)['urnData'];
	}

	public get id(): id {
		return this.get('id', '');
	}

	public get urn() {
		return this.get('@urn', '');
	}

	public get iri(): string {
		return this.get('@id', '');
	}

	public get pagePath() {
		return this.path;
	}

	public static makeUrn(id: id): Urn {
		return `${this.urnData.partition}:${this.urnData.service}:${this.urnData.resource}:${id}`;
	}

	public static pagePath(type?: 'list' | 'splitView') {
		switch (type) {
			case 'list':
				return (new ApiCollection(this as never)).path.slice(0, -1);
			default:
				return (new ApiCollection(this as never)).path.slice(0, -1) + '/split';
		}
	}

	public save(options: ConnectorSaveOptions = {}) {
		return super.save(options);
	}

	public patch(attributes: Record<string, unknown> = {}, options: ConnectorSaveOptions = {}) {
		return this.save({ ...options, patchAttributes: attributes });
	}

	public patchFormData(data: Record<string, unknown> = {}, options: any = {}) {
		const formData = new FormData();

		const attributes = flattenObject(data, '', true);

		Object.keys(attributes).forEach((key) => {
			const value = attributes[key];

			// On transforme les types "boolean" en 1 ou 0
			if (typeof value === 'boolean') attributes[key] = value ? 1 : 0;
			// On supprime les types "undefined"
			else if (typeof value === 'undefined') delete attributes[key];
			// On supprime les types "null"
			else if (value === null) delete attributes[key];
		});

		Object.keys(attributes).forEach((key) => {
			const value = attributes[key];

			if (Array.isArray(value)) {
				(value as []).forEach((subValue, index) => {
					formData.append(`${key}[${index}]`, subValue);
				});
			} else {
				formData.append(key, value as (string | Blob));
			}
		});

		return this.save({
			...options,
			headers: { 'Content-Type': undefined, ...options.headers },
			patchAttributes: formData,
			body: formData,
		});
	}

	public get isEmpty() {
		// Lorsque tous les attributs d'un models sont undefined, on considère que c'est un model "vide".
		// Sauf si les trois propriétés (id + urn + iri) sont renseignées.

		return (
			this.isLoaded
			&& !this.isLoading
			&& (!this.id || !this.urn || !this.iri)
			&& Object.values((value: any) => typeof value === 'undefined')
		);
	}

	@action
	public setIsLoaded(value = true) {
		this.isLoaded = value;
	}

	public getId(propName?: Extract<keyof this, string>) {
		const propNameUrn = this.get(`${propName}Urn`);

		return propName ? (
			this.get(`${propName}.id`)
			|| (propNameUrn ? getIdFromUrn(propNameUrn) : undefined)
			|| _get(this, `${propName}.id`)
		) : this.id;
	}

	public getUrn(propName?: Extract<keyof this, string>): Urn {
		return propName ? (
			this.get(`${propName}.@urn`)
			|| this.get(`${propName}Urn.@urn`)
			|| (typeof this.get(`${propName}Urn`) === 'string' ? this.get(`${propName}Urn`) : false)
			|| (this[propName] as any)['urn']
		) : this.urn;
	}

	public get modelLabel(): string {
		const properties = ['fullName', 'lastName', 'firstName', 'label', 'title', 'name', 'code', 'reference', 'id'];
		const property = properties.find(v => _get(this, v, '') || _get(this.attributes, v, ''));
		return property ? (_get(this, property, '') || _get(this.attributes, property, '')) : '';
	}

	public getResolvableModelClass(_propName: string, _attributeName: string, attribute: any): ResolvableModelClass<AbstractResolvableModel> | undefined | null {
		if (typeof attribute === 'string') { // URN ?
			const urnModelClass = ModelDictionary.get(attribute);
			return urnModelClass as unknown as ResolvableModelClass<AbstractResolvableModel> || undefined;
		} else if (attribute && attribute['@urn']) {
			const urnModelClass = ModelDictionary.get(attribute['@urn']);
			if (urnModelClass) {
				return urnModelClass as unknown as ResolvableModelClass<AbstractResolvableModel> || undefined;
			}
		}

		return null;
	}

	protected getResolvableAttributes(_propName: string, _attributeName: string, attribute: any) {
		if (typeof attribute === 'string') { // Permet de gérer les resolvable @doc.modelUrn
			return { '@id': attribute, '@type': 'urn', '@urn': attribute, id: parseInt(getIdFromUrn(attribute as Urn)) };
		}
		return attribute;
	}

	public fetch(options?: ConnectorFetchOptions) {
		return super.fetch(options);
	}

	@override onError(message: string, error: any) {
		if (error.code === 'ERR_CANCELED') {
			console.warn('Requête annulée', this.path);
			return;
		}

		super.onError(message, error);
	}

	public get<VALUE = any, DEFAULT_VALUE = undefined>(
		attribute: string | string[],
		defaultValue?: DEFAULT_VALUE,
	): VALUE | DEFAULT_VALUE {
		return super.get(attribute, defaultValue);
	}
}
